@charset "utf-8";

// Modules and Variables
@import "mixin";


* {
    -webkit-tap-highlight-color: rgba(0,0,0,0); /* make transparent link selection, adjust last value opacity 0 to 1.0 */
}

html {
    -ms-touch-action: none;
}


body {
    -webkit-touch-callout: none;                /* prevent callout to copy image, etc when tap to hold */
    -webkit-text-size-adjust: none;             /* prevent webkit from resizing text to fit */
    -webkit-user-select: none;                  /* prevent copy paste, to allow, change 'none' to 'text' */
    background-color:#d7d7d7;
    // font-size: 21px;
    font-size:18px;
    margin:0px;
    padding:0px;
    width:100%;
    height:100%;
    color: #00622b;
    font-family: 'garapro';
    overflow: hidden; /* this is important to prevent the whole page to bounce */
}



@font-face {
    font-family: 'garapro';
    src: url('fonts/garapro-bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}
@font-face {
    font-family: 'garapro';
    src: url('fonts/garapro-bolditalic.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
}
@font-face {
    font-family: 'garapro';
    src: url('fonts/garapro-italic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
}
@font-face {
    font-family: 'garapro';
    src: url('fonts/garapro-regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}


.img-resp {
    width: 100%;
    height: auto;
}

app {
    position: relative;
}

$header_divider: 3 / 2;

header {
    position: relative;
    height: floor(111px / $header_divider);
    min-width: floor(643px / $header_divider);
    border-bottom: 1px solid white; 
    z-index: 50;

    @media all and (orientation: landscape) {
        min-width: floor(839px / $header_divider);
    }

    .left {
        float: left;
        width: floor(384px / $header_divider);
        height: floor(111px / $header_divider);
    }
    .right {
        float: right;
        width: floor(254px / $header_divider);
        height: floor(111px / $header_divider);
    }
    .bottom {
        position: absolute;
        bottom: -27px;
        height: 26px;
    }
}

ul {
    margin: 0;
    padding: 0;
}

li {
    list-style: none;
}


.section-left {
    z-index: 10;


    #wrapper {
        position: relative;
        //@include calc(height, '100% - 266px');
        overflow: hidden;

        margin-left: 50px;
        

        /* Prevent native touch events on Windows */
        -ms-touch-action: none;

        /* Prevent the callout on tap-hold and text selection */
        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;

        /* Prevent text resize on orientation change, useful for web-apps */
        -webkit-text-size-adjust: none;
        -moz-text-size-adjust: none;
        -ms-text-size-adjust: none;
        -o-text-size-adjust: none;
        text-size-adjust: none;
    }

    #scroller {
        position: absolute;
        -webkit-tap-highlight-color: rgba(0,0,0,0);
        width: 100%;
        @include calc(width, '100% - 150px');
        -webkit-transform: translateZ(0);
        -moz-transform: translateZ(0);
        -ms-transform: translateZ(0);
        -o-transform: translateZ(0);
        transform: translateZ(0);
        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        -webkit-text-size-adjust: none;
        -moz-text-size-adjust: none;
        -ms-text-size-adjust: none;
        -o-text-size-adjust: none;
        text-size-adjust: none;
    }

    ul {
        padding: 30px 0 100px 0;
        @include box-sizing();
    }
    li {
        padding-bottom: 20px;
    }

    .title {
        font-weight: bold;
        padding-bottom: 6px;
    }
    .icon {
        width: 14px;
        height: 14px;
        position: absolute;
        margin-left: 2px;
        margin-top: 1px;
        left: -2px;
    }
    .song {
        cursor: pointer;
        margin-left: 20px;
        //width: 600px;

        &.active {
            color: black;
        }
    }

    .separation {
        padding: 35px 0;
        text-align: center;
    }
}

// .platform-ios {
//     .section-left {
//         #scroller {
//          @include calc(width, '100% - 150px');
//         }
//         .separation {
//             font-size: 16px;
//         }
//     }
// }

.section-player {

    background-color: white;
    width: 100%;
    height: 135px;
    // height: 200px;
    position: fixed;
    bottom: 0;
    z-index: 70;
    text-align: center;

    .top-shadow {
        display: none;
        position: absolute;
        top: -26px;
        height: 26px;
        width: 100%;
        img {
            width: 100%;
            height: 26px;
        }
    }


    .author {
        margin-top: 31px;
        font-weight: bold;
    }

    .title {
        padding: 0 25px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        text-align: center;
    }

    #audioplayer {
        width: 100%;
        position: absolute;
        // bottom: 40px;
        bottom: -10px;

        .btn-player {
            width: 56px;
            height: 56px;
            cursor: pointer;
            position: absolute;
            // margin-left: 227px;
            // margin-left: 27px;
            bottom: 26px;
            left: 50%;
        }
        .btn-play {
            background: url('../img/player_play.png') no-repeat;
            background-size: 25% 25%;
            background-position: center;
        }
        .btn-pause {
            background: url('../img/player_pause.png') no-repeat;
            background-size: 25% 25%;
            background-position: center;
        }
        #timeline{
            //width: percentage(1086, 1536);
            width: percentage(861, 1080);
            height: 6px;
            background: #9a9a9a;
            position: absolute;
            bottom: 50px;
            left: 50%;
            margin-left: -271px;
        }
         
        #playhead{
            width: 0px;
            height: 6px;
            background: black;
        }
        .duration {
            position: absolute;
            // margin-right: 235px;
            margin-right: 35px;
            bottom: 43px;
            font-size: 14px;
            right: 50%;
        }
    }
}

// .platform-ios {
//     .section-player  {
//         #audioplayer {
//             .btn-player {
//                 margin-left: 27px;
//             }
//             .duration {
//                 margin-right: 35px;
//             }
//         }
//     }
// }

.section-left-landscape {
    display: none;
    width: 100px;
    position: fixed;
    left: 0;
    border-right: 1px solid white; 
    height: 100%;
    top: 111px / $header_divider;
    z-index: 60;
}

.section-right {

    width: 100px;
    position: fixed;
    right: 0;
    z-index: 60;
    border-left: 1px solid white; 
    height: 100%;
    top: 111px / $header_divider;
    text-align: center;
    font-weight: bold;

    ul {
        margin-top: 36px;
    }
    ul li {
        margin-bottom: 3px;
        cursor: pointer;
        font-size: 17px;
        &.disable {
            opacity: .2;
        }
    }
}

// .platform-ios {
//     ul {
//         margin-top: 10px;
//     }
//     ul li {
//         margin-bottom: 1px;
//     }

// }


@media all and (orientation: landscape) {
    

    header {

        .left {
            margin-left: 100px;
        }
        .right {
            margin-right: 100px;
        }
    }

    .section-player {
        margin-left: 100px;
        @include calc(width, '100% - 200px');

        #timeline{
        }
    }

    .section-left {
        margin-left: 100px;
    }

    .section-left-landscape {
        display: block;
    }
    .section-right ul  {
        margin-top: 33px;
    }

    .section-right ul li {
        // margin-bottom: 9px;
        // font-size: 21px;
        margin-bottom: 3px;
        font-size: 15px;
    }
}

// ------------------------------------------------------------------------------------------------

.platform-ios {

    // @import 'ios';

}




