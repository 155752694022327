

/* @include calc($property, $expression); */
// usage @include calc(width, '100% - 10px');
@mixin calc($property, $expression) { 
  #{$property}: -moz-calc(#{$expression});
  #{$property}: -webkit-calc(#{$expression}); 
  #{$property}: -o-calc(#{$expression});
  #{$property}: calc(#{$expression}); 
} 

// usage @include transition(all .2s ease-in-out);
@mixin transition($transition...) {
    -moz-transition:    $transition;
    -o-transition:      $transition;
    -webkit-transition: $transition;
    transition:         $transition;
}
// percentage
@function percentage($target, $container) {
  @return ($target / $container) * 100%;
}

// vertical align
@mixin vertical-align($position: relative) {
  position: $position;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
// TRANSFORM
// generic transform
@mixin transform($transforms) {
     -moz-transform: $transforms;
       -o-transform: $transforms;
      -ms-transform: $transforms;
  -webkit-transform: $transforms;
          transform: $transforms;
}
// rotate
@mixin rotate ($deg) {
  @include transform(rotate(#{$deg}deg));
}
// scale
@mixin scale($scale) {
   @include transform(scale($scale));
} 
// translate
@mixin translate ($x, $y) {
   @include transform(translate($x, $y));
}
// skew
@mixin skew ($x, $y) {
   @include transform(skew(#{$x}deg, #{$y}deg));
}
@mixin filterscale() {
    -moz-filter: scale(0);
    -o-filter: scale(0);
    -ms-filter: scale(0);
    -webkit-filter: scale(0);
    filter: scale(0);
}

@mixin box-sizing{
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -o-box-sizing: border-box;
}